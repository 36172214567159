import React from "react"

import containerStyles from "../styles/global.css"

const IndexPage = () => {
    return (
        <span>

        <h1>Hi <span role="img" aria-label="spock"> 🖖</span> !!!</h1>
        <p>My name is Carlos Blázquez Sumastre aka <b>"cbsumastre"</b>.</p>
        <p>I am a senior software developer and passionate about technology.</p>
        <p>If you want to know my work you can find it on <a href="https://github.com/cbsumastre" target="new">github</a>.</p>
        <p>Outside of work, I enjoy tv shows, video games, music, my friends and my little nephews.</p>
        <div className="social">
            <a className="social-icon" data-tooltip="carlos.blazquez@firstflip.es" href="mailto:carlos.blazquez@firstflip.es" target="new">
                <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
            </a>

            <a className="social-icon" data-tooltip="GitHub" href="https://github.com/cbsumastre" target="new">
                <i className="fa fa-github fa-2x" aria-hidden="true"></i>
            </a>

            <a className="social-icon" data-tooltip="LinkedIn" href="https://es.linkedin.com/in/cbsumastre" target="new">
                <i className="fa fa-linkedin fa-2x" aria-hidden="true"></i>
            </a>

            <a className="social-icon" data-tooltip="Twitter" href="https://twitter.com/cbsumastre" target="new">
                <i className="fa fa-twitter fa-2x" aria-hidden="true"></i>
            </a>
        </div>
        </span>
    )
}


export default IndexPage
